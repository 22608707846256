.tabs__panel {
  display: none;
}

.tabs__nav {
  list-style: none;
  display: flex;
}

.tabs__panel--active {
  display: block;
}

.tabs__nav-btn:focus {
  color: #fff;
}

.tabs__nav-btn--active {
  color: #c59b3d;
  border: 1px solid #c59b3d;
  transition: border .3s, box-shadow .3s;
  box-shadow: 0 36px 47px -18px rgba(197, 155, 61, .65);
}

/*# sourceMappingURL=index.af0520da.css.map */
